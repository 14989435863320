<template>
  <div class="card noselect" v-on:click="openDetailPage">
    <div class="container" >
      <div class="container" v-if="this.type == 'astro'">
        <img
          v-if="getImageUrls"
          id="img"
          @load="loaded"
          :src="require(`@/assets/static/images/${imageUrl}`)"
          class="noselect"
        />
      </div>
      <div  class="container" v-if="this.type == 'proj'">
        <img
          v-if="getImageUrls"
          id="img"
          @load="loaded"
          :src="require(`@/assets/static/images/${imageUrls[0].filePath}`)"
          class="noselect"
        />
      </div>
    </div>
    <p v-if="this.type == 'astro'">
      {{ image.name }} ({{ image.data.captureInfo.date }})
    </p>
    <p v-if="this.type == 'proj'">{{ image.name }}</p>
  </div>
</template>

<script>
import router from "../router/index";

export default {
  name: "ImageCard",
  props: ["image", "type"],
  data() {
    return {
      imageUrls: [],
      imageUrl: "",
    };
  },
  computed: {
    getImageUrls() {
      return this.imageUrls.length;
    },
  },
  methods: {
    loadPicture: function () {
      this.isLoad = false;
      const urls = [];
      this.image.data.images.forEach((element) => {
        // console.log(element);
        const object = {};
        object["index"] = this.image.data.images.indexOf(element);
        object["filePath"] = element.imagePath;
        urls.push(object);
      });
      urls.sort((a, b) => a.index - b.index);
      this.imageUrls = urls;
      this.imageUrl = this.image.data.thumb;
      this.loaded();
    },

    loaded() {
      this.$emit("loaded");
    },

    openDetailPage: function () {
      if (this.type === "astro") {
        // console.log("pressed");
        router.push({
          name: "ImageDetails",
          params: {
            imageName: this.image.name,
            image: this.image,
            image_urls: this.imageUrls,
          },
        });
      } else if (this.type === "proj") {
        console.log("pressed");
        router.push({
          name: "ProjectDetails",
          params: {
            projectName: this.image.name,
            image: this.image,
            image_urls: this.imageUrls,
          },
        });
      }
    },
  },
  mounted() {
    this.loadPicture();
  },
};
</script>

><style scoped>
.card {
  width: 350px;
  height: 400px;
  padding: 20px;
  margin: auto;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
}

.card p {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 10px;
  font-size: 1.4em;
}

.container {
  width: 100%;
  height: 80%;
  text-align: center;
}

#img {
  width: 300px;
  height: 300px;
  overflow: hidden;
  object-fit: contain;
}
</style>