<template>
  <div>
    
  </div>
</template>

<script>
import router from "../router/index";

export default {
  name: "NotFound",
  mounted() {
    router.push({
          name: "Home",
          params: {
            projectName: this.image.name,
            image: this.image,
            image_urls: this.imageUrls,
          },
        });
  },
};
</script>

<style scoped>

</style>