<template>
  <div class="content">
    <div class="projects" v-show="isLoad">
      <div class="project" v-for="project in projects" :key="project.name">
        <image-card
          v-on:loaded="loaded"
          v-bind:image="project"
          v-bind:type="'proj'"
        />
      </div>
    </div>
    <sync-loader class="loader" :loading="loading"></sync-loader>
  </div>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import ProjectsJson from "../assets/json/projects.json";
import ImageCard from "../components/ImageCard.vue";

export default {
  name: "DevProjects",
  components: {
    SyncLoader,
    ImageCard,
  },
  data() {
    return {
      projects: [],
      isLoad: false,
      loading: true,
      count: 0,
      projectsJson: ProjectsJson,
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    loaded() {
      this.count = this.count + 1;
      if (this.count === this.projects.length) {
        this.isLoad = true;
        this.loading = false;
      }
    },

    getData: function () {
      const projs = [];
      this.projectsJson.projects.forEach((element) => {
        // console.log(element);
        projs.push(element);
        projs.sort((a, b) => b.projectName - a.projectName);
      });

      // console.log(this.images);
      this.projects = projs;
    },
  },
};
</script>

<style scoped>
.projects {
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  max-width: 1020px;
  margin-top: 40px;
}

.project {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.content {
  display: flex;
  justify-content: center;
  height: 100%;
}

.loader {
  text-align: center;
  margin: 0 auto;
  width: 50%;
  padding: 10px;
}
</style>