<template>
  <div class="content">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <div class="noselect carousel-container">
      <carousel
        @page-change="onPageChange"
        class="carousel"
        :scrollPerPage="false"
        :perPage="1"
        :navigationEnabled="true"
        navigationNextLabel="<i class='material-icons white'>keyboard_arrow_right</i>"
        navigationPrevLabel="<i class='material-icons white'>keyboard_arrow_left</i>"
      >
        <slide class="slide" v-for="image in storeUrls" :key="image.filePath">
          <img
            id="img"
            class="noselect"
            :src="require(`@/assets/static/images/${image.filePath}`)"
          />
        </slide>
      </carousel>
    </div>

    <div class="body">
      <button class="fullscreenBtn" v-on:click="fullscreen">
        View Fullscreen
      </button>
      <h1>{{ storeImage.name }}</h1>
      <div class="links" v-for="link in storeImage.data.links" :key="link.name">
        <h4>
          <a :href="link.link" target="_blank"><b>{{ link.name }} Wikipedia</b></a>
        </h4>
      </div>
      <p id="desc">{{ storeImage.data.desc }}</p>
      <h2>Capture Info</h2>
      <ul>
        <li><b>Date:</b> {{ storeImage.data.captureInfo.date }}</li>
        <li>
          <b>Lights:</b> {{ storeImage.data.captureInfo.lights }}x{{
            storeImage.data.captureInfo.lights_seconds
          }}"
        </li>
        <li><b>Darks:</b> {{ storeImage.data.captureInfo.darks }}</li>
        <li><b>Bias:</b> {{ storeImage.data.captureInfo.bias }}</li>
        <li><b>Flats:</b> {{ storeImage.data.captureInfo.flats }}</li>
        <li><b>ISO:</b> {{ storeImage.data.captureInfo.iso }}</li>
        <li>
          <b>Total integration time:</b> {{ this.getIntegrationTime() }} hours
        </li>
      </ul>
      <h2>Gear</h2>
      <ul>
        <li>
          <b>Imaging Camera:</b> {{ storeImage.data.gear.imaging_camera }}
        </li>
        <li>
          <b>Imaging Telescope/Lens:</b>
          {{ storeImage.data.gear.imaging_telescope }}
        </li>
        <li><b>Guide Camera:</b> {{ storeImage.data.gear.guide_camera }}</li>
        <li><b>Guide Scope:</b> {{ storeImage.data.gear.guide_scope }}</li>
        <li><b>Mount:</b> {{ storeImage.data.gear.mount }}</li>
        <li><b>Filters:</b> {{ storeImage.data.gear.filters }}</li>
        <li><b>Software:</b> {{ storeImage.data.gear.software }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import createPersistedState from "vuex-persistedstate";
import Vuex from "vuex";

const store = new Vuex.Store({
  state: {
    storeImage: null,
    storeUrls: null,
  },
  plugins: [createPersistedState()],
  mutations: {
    setData: (state, { img, urls }) => {
      // console.log(img);
      // console.log(urls);
      // console.log("setData");
      state.storeImage = img;
      state.storeUrls = urls;
    },
  },
});

export default {
  name: "ImageDetail",
  props: ["image", "image_urls"],
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      count: 0,
      dataImage: null,
      dataImageUrls: null,
    };
  },
  computed: {
    storeImage() {
      return store.state.storeImage;
    },
    storeUrls() {
      return store.state.storeUrls;
    },
  },
  methods: {
    onPageChange(currentPage) {
      this.count = currentPage;
      // console.log(`page changed to ${currentPage}`);
    },

    fullscreen: function () {
      window.open(
        require(`@/assets/static/images/${
          store.state.storeUrls[this.count].filePath
        }`)
      );
    },

    getIntegrationTime: function () {
      const seconds =
        parseInt(store.state.storeImage.data.captureInfo.lights) *
        parseInt(store.state.storeImage.data.captureInfo.lights_seconds);
      const hours = seconds / (60 * 60);
      const roundedHours = Math.round((hours + Number.EPSILON) * 100) / 100;
      //   console.log(hours);
      return roundedHours;
    },

    setStore: function () {
      if (this.image !== undefined && this.image_urls !== undefined) {
        const img = this.image;
        const urls = this.image_urls;
        // console.log(img);
        // console.log(urls);
        store.commit("setData", {
          img,
          urls,
        });
      }
    },
  },
  mounted() {
    this.setStore();
  },
};
</script>

<style scoped>
.content {
  margin: auto;
  padding: 20px;
  max-width: 1640px;
}

.body h1 {
  text-align: center;
  font-size: 2em;
}

.body h2 {
  text-decoration: underline;
  text-align: start;
  max-width: 400px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.body ul {
  max-width: 400px;
  margin: auto;
  list-style: none;
  text-align: start;
}

#desc {
  margin: auto;
  font-size: 1.2em;
  max-width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.body {
  text-align: center;
  padding-bottom: 100px;
}

.slide {
  margin: auto;
}

.slide img {
  width: 60%;
}

img {
  object-fit: contain;
}

@media only screen and (max-width: 1000px) {
  img {
    object-fit: contain;
  }

  .slide img {
    width: 100%;
  }
}

.carousel {
  margin: auto;
  text-align: center;
}

.carousel-container {
  margin: auto;
}

.fullscreenBtn {
  padding: 0.35em 1.2em;
  width: 150px;
  border-radius: 0.12em;
  border: 0px;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.2s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 20px;
}

.fullscreenBtn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

h4 a {
  text-decoration: none;
  color: rgb(68, 156, 192);
}
</style>