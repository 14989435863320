<template>
  <div class="content">
    <div class="pictures" v-show="isLoad">
      <div class="picture" v-for="image in images" :key="image.data.desc">
        <image-card
          v-on:loaded="loaded"
          v-bind:image="image"
          v-bind:type="'astro'"
        />
      </div>
    </div>
    <sync-loader class="loader" :loading="loading"></sync-loader>
  </div>
</template>

<script>
// import AstroImage from "../components/AstroImage.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import PicturesJson from "../assets/json/pictures.json";
import ImageCard from "../components/ImageCard.vue";

export default {
  name: "AstroGallery",
  components: {
    // AstroImage,
    SyncLoader,
    ImageCard,
  },
  data() {
    return {
      images: [],
      isLoad: false,
      loading: true,
      count: 0,
      picturesJson: PicturesJson,
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    loaded() {
      this.count = this.count + 1;
      if (this.count === this.images.length) {
        this.isLoad = true;
        this.loading = false;
      }
    },

    getData: function () {
      const pictures = [];
      this.picturesJson.pictures.forEach((element) => {
        // console.log(element);
        pictures.push(element);
        pictures.sort(
          (a, b) =>
            new Date(b.data.captureInfo.date) -
            new Date(a.data.captureInfo.date)
        );
      });

      // console.log(this.images);
      this.images = pictures;
    },
  },
};
</script>

<style scoped>
.pictures {
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  max-width: 1020px;
  margin-top: 30px;
}

.picture {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.content {
  display: flex;
  justify-content: center;
}

.loader {
  text-align: center;
  margin: 0 auto;
  width: 50%;
  padding: 10px;
}
</style>